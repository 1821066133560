import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import Emoji from "./emoji";
const GalleryPreview = () => {

  const data = useStaticQuery(graphql`
    query galleryPreviewQuery {
      allInstagramContent(limit: 4) {
        edges {
          node {
            caption
            media_url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allInstagramContent.edges.map(edge => ({
    image: getImage(edge.node.localFile),
    caption: edge.node.caption
  }));

  return (
    <section className="bg-primary text-white">
      <div className="container pt-12 pb-20">
        <div className="flex items-center mb-8">
          <h1>Latest Drawings</h1>
          <StaticImage className="w-20 ml-4" src="../images/gallery.png" alt="eye" placeholder="blurred"/>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
            {images.map((image, index) => (
              <div key={`preview-${index}`}>
                <GatsbyImage image={image.image} alt={image.caption} />
              </div>
            ))}
        </div>
        <p className="text-center text-2xl font-normal mt-5">
          For more <Emoji emoji="&#128073;" label="pointing finger"/> <Link to="/gallery"> visit the gallery</Link>.
        </p>
      </div>
    </section>
)
}

export default GalleryPreview
