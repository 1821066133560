import React from "react";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";

const Testimonial = ({ name, designation, thumbnail, content }) => {
  return (
    <div className="flex flex-col justify-center items-end mx-2 mb-20">
      <div className="flex items-start mb-6">
        <p className="text-left w-60 my-0">
          {content}
        </p>
        <StaticImage className="w-10 ml-2" src="../images/testimonial.svg" alt="quotation mark" placeholder="blurred"/>
      </div>
      <div className="flex md:flex-row-reverse items-center">
        <GatsbyImage alt={name}
                     image={thumbnail}
                     placeholder="blurred"
                     className="rounded-50 mr-2 md:mr-0 md:ml-2"
                     imgStyle={{ borderRadius: '100%' }}/>
        <div className="flex flex-col md:text-right">
          <h4 className="my-0">{name}</h4>
          <span className="text-gray-500">{designation}</span>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
