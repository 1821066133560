import React from "react";
import {getImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import Testimonial from "./testimonial";

const Testimonials = () => {

  const data = useStaticQuery(graphql`
    query testimonials {
      dali: file(relativePath: {eq: "dali.jpeg"}) {
        childImageSharp {
          gatsbyImageData(width: 50)
        }
      }
      breton: file(relativePath: {eq: "breton.jpeg"}) {
        childImageSharp {
          gatsbyImageData(width: 50)
        }
      }
      kahlo: file(relativePath: {eq: "kahlo.jpeg"}) {
        childImageSharp {
          gatsbyImageData(width: 50)
        }
      }
    }
  `);

  const testimonials = [
    {
      name: 'Salvador Dalì',
      designation: 'Expert on Dreams and Hallucinations',
      content: 'Man, this app lets you create art that not even I could have imagined. My daily source of inspiration!',
      thumbnail: getImage(data.dali)
    },
    {
      name: 'Andre Breton',
      designation: 'Founder of Surrealism',
      content: 'I wish this app had existed back in our time! Best way to create exquisite corpses. Will definitely link to it from my Surrealist Manifesto.',
      thumbnail: getImage(data.breton)
    },
    {
      name: 'Frida Kahlo',
      designation: '– on completing her first ScribbleX',
      content: 'Feet, what do I need them for if I have wings to fly.',
      thumbnail: getImage(data.kahlo)
    },
  ]

  return (
    <section>
      <div className="container pt-12 pb-8">
        <div className="flex flex-wrap justify-around items-start">
          {testimonials.map((testimonial, index) => (
            <Testimonial key={`testimonial-${index}`}
                         name={testimonial.name}
                         designation={testimonial.designation}
                         content={testimonial.content}
                         thumbnail={testimonial.thumbnail}/>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Testimonials
