import React from "react"
import {StaticImage} from "gatsby-plugin-image";
const Trailer = () => {
  return (
    <section>
      <div className="container pb-16">
        <div className="flex items-center justify-center mb-8">
          <StaticImage className="w-20 mr-4" src="../images/eye.svg" alt="eye" placeholder="blurred"/>
          <h1>Watch the Trailer</h1>
        </div>
        <div className="max-w-3xl mx-auto">
          <div className="iframe-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/nzUh3R4RN2k" title="ScribbleX Trailer"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Trailer
