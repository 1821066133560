import * as React from "react"
import Layout from "../components/layout";
import Banner from "../components/banner";
import HowTo from "../components/how-to";
import Trailer from "../components/trailer";
import GalleryPreview from "../components/gallery-preview";
import Testimonials from "../components/testimonials";
import InTheNews from "../components/in-the-news";

// markup
const IndexPage = () => {
  return (
    <Layout title="Home">
      <Banner/>
      <Testimonials/>
      <HowTo/>
      <Trailer/>
      <GalleryPreview/>
      <InTheNews/>
    </Layout>
  )
}

export default IndexPage
