import React from "react"
import {StaticImage} from "gatsby-plugin-image";
import Emoji from "./emoji";

const HowTo = () => {
  return (
    <section>
      <div className="container pb-16">
        <div className="text-center sm:text-left">
        <div className="flex items-center justify-center">
          <h1>How to play</h1>
          <StaticImage className="w-20 ml-4"
                       src="../images/question.svg"
                       placeholder="blurred"
                       alt="question"/>
        </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="mb-10 text-left">
              <h2 className="font-weight-bold mb-2"><Emoji emoji="🐰" label="head"/> Draw the head</h2>
              <p>
                Start the game by drawing the head or the face of the figure.<br/> When you are done, send the drawing to the next player.
              </p>
            </div>
            <div className="mb-10 text-left">
              <h2 className="font-weight-bold mb-2"><Emoji emoji="🧥" label="body"/> Draw the body</h2>
              <p>
                Player 2 continues the game by drawing the body.
                <br/>Again, they send it to another player.</p>
            </div>
            <div className="mb-10 text-left">
              <h2 className="font-weight-bold mb-2"><Emoji emoji="🩰" label="feet"/> Draw the feet</h2>
              <p>
                Player 3 draws the feet of the figure. <br/>
                When all players are done, the drawing is revealed. Voilà!
              </p>
            </div>
          </div>
          <div className="flex items-center">
              <StaticImage src="../images/endless-possibilities.png"
                           placeholder="blurred"
                           alt="showcase"/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowTo
