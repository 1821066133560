import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";

const InTheNews = () => {
  const data = useStaticQuery(graphql`
    query inTheNews {
      gameskeys: file(relativePath: {eq: "gameskeys.png"}) {
        childImageSharp {
          gatsbyImageData(width: 250)
        }
      }
    }
  `)

  const inTheNews = [
    {
      publisher: 'GamesKeys.net',
      url: 'https://gameskeys.net/underrated-family-games-to-play-with-your-family-on-ios/#ScribbleX',
      logo: getImage(data.gameskeys)
    }
  ]

  return (
    <section>
      <div className="container pt-12 pb-20">
        <div className="flex items-center justify-center mb-8">
          <h1>News</h1>
        </div>
        <div className="flex justify-around">
          { inTheNews.map((news, index) => (
            <a key={`news-mention-${index}`} className="" href={news.url} target="_blank" rel="noreferrer">
              <GatsbyImage className="filter grayscale opacity-30 hover:opacity-100 hover:grayscale-0 focus:opacity-100 focus:grayscale-0 transition"
                           alt={news.publisher}
                           placeholder="blurred"
                           image={news.logo}/>
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default InTheNews
