import React from "react";
import AppStore from "./appstore";
import AppPreviewH264 from "../videos/preview_300x620_H264.mp4";
import AppPreviewHEVC from "../videos/preview_300x620_HEVC.mp4";
import AppPreviewVP9 from "../videos/preview_300x620_VP9.webm";

const Banner = () => {
  return (
    <section className="bg-primary text-white">
      <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 pt-8 pb-20 max-w-7xl">
        <div className="flex justify-center">
          <div className="filter drop-shadow-2xl" style={{borderRadius: "46px", overflow: 'hidden', width: '300px', height: '620px'}}>
            <video preload="auto" autoPlay muted loop playsInline>
              <source src={AppPreviewHEVC} type="video/mp4"/>
              <source src={AppPreviewVP9} type="video/webm"/>
              <source src={AppPreviewH264} type="video/mp4"/>
            </video>
          </div>
        </div>
        <div className="flex flex-col justify-center text-center md:text-left mt-6 md:mt-0">
          <h1 className="font-semibold mb-4">Draw like<br/>Salvador Dalì</h1>
          <p className="text-xl font-normal mb-4">
            Create crazy, funny, creative art together with your friends or
            with random artists around the world.
            The only limit is your imagination.<br/><br/>
            Try it out, it's free!
          </p>
          <div className="relative mx-auto md:mx-0 md:-left-3.5">
            <AppStore/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
